const Textarea = {
  baseStyle: {
    color: 'mode.900',
  },
  defaultProps: {
    focusBorderColor: 'link.500',
  },
};

export default Textarea;
